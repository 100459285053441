<app-notification-bar></app-notification-bar>
<nav>
  <!-- notification Bar -->
  <!-- navbar mobile -->
  <div *ngIf="isMobile" class="mobile">
    <a routerLink="/" (click)="goHome()"><img src="assets/{{ logoUrl }}" class="h-8" alt="{{ logoAlt }}" /></a>
    <div>
      <button aria-label="Mon compte" class="user" [matMenuTriggerFor]="account" (click)="isAuthentified()">
        <fa-icon [icon]="['fas', 'user']" style="color: #006699" size="lg"></fa-icon>
      </button>
      <mat-menu #account="matMenu">
        <ng-template matMenuContent>
          <ng-container *ngIf="!isAuth">
            <a routerLink="/account" mat-menu-item>Connexion</a>
            <a routerLink="/account/inscription" mat-menu-item>Inscription</a>
          </ng-container>
          <ng-container *ngIf="isAuth">
            <a routerLink="/account/tableau-de-bord/accueil" mat-menu-item>Mon espace</a>
            <a routerLink="/account/tableau-de-bord/informations" mat-menu-item>Mon profil</a>
            <a routerLink="/account/tableau-de-bord/commandes" mat-menu-item>Mes commandes</a>
            <a routerLink="/account/tableau-de-bord/reprises" mat-menu-item>Mes reprises</a>
            <a routerLink="/account/tableau-de-bord/alertes" mat-menu-item>Mes alertes</a>
            <a routerLink="/account/tableau-de-bord/recherches" mat-menu-item>Mes recherches</a>
            <a routerLink="/account/tableau-de-bord/favoris" mat-menu-item>Mes favoris</a>
            <a routerLink="/account/tableau-de-bord/parametres" mat-menu-item>Paramètres</a>
            <hr class="custom-hr" />
            <button mat-menu-item (click)="signedOut()">Déconnexion</button>
          </ng-container>
        </ng-template>
      </mat-menu>
      <span class="px-3 text-lg">|</span>
      <button class="text-base pr-3" aria-label="Menu" (click)="toggleMenu.emit()">
        <fa-icon [icon]="'bars'"></fa-icon>
      </button>
    </div>
  </div>

  <!-- new navbar Desktop -->
  <div *ngIf="!isMobile" class="container">
    <!-- white navabar -->
    <div>
      <div class="containerLogo">
        <a routerLink="/" (click)="goHome()">
          <img src="assets/{{ logoUrl }}" alt="{{ logoAlt }}" class="h-14" />
        </a>

        <ul class="route">
          <li>
            <a class="navbarLink" (focus)="setPopover('vehicle')" aria-hidden="true" (mouseover)="setPopover('vehicle')"
              (click)="goCatalogAndResetFilters()">Vehicules</a>
          </li>

          <li><a class="navbarLink" routerLink="/reprise">Reprise</a></li>

          <li><a class="navbarLink" routerLink="/financement">Financement</a></li>

          <li aria-haspopup="true" aria-expanded="false" style="z-index: 1" class="!text-black"
            (mouseover)="setPopover('')" aria-hidden="true" (focus)="setPopover('')">
            <a class="navbarLink" routerLink="/informations/services">Services</a>
            <ul class="dropdown-menu" arialabel="Services">
              <li>
                <a routerLink="/informations/services/achat-en-ligne">Achat en ligne</a>
              </li>
              <li>
                <a routerLink="/informations/services/livraison">Livraison</a>
              </li>
              <li>
                <a routerLink="/informations/achat/Reconditionnement">Reconditionnement</a>
              </li>
              <li>
                <a routerLink="/garantie-voiture-neuve-occasion">Garantie</a>
              </li>
              <li>
                <a routerLink="/informations/commande-voiture-sur-mesure">Commande sur mesure</a>
              </li>

              <li>
                <a routerLink="/service-apres-vente">Service après-vente</a>
              </li>
            </ul>
          </li>
          <li><a class="navbarLink" routerLink="/agences">Agences</a></li>
          <li>
            <a target="_blank" href="https://blog.oxylio.com/" class="navbarLink">Conseils</a>
          </li>
        </ul>

        <div>
          <button class="contactButton" routerLink="/contact" (mouseenter)="onHover(true)" (mouseleave)="onHover(false)"
            [ngClass]="{'swap-to-connexion': isHovering}">
            Contact
          </button>
          <button [matMenuTriggerFor]="account" (click)="isAuthentified()" class="connexionButton"
            [ngClass]="{'swap-to-contact': isHovering}">
            <span *ngIf="!isAuth" matTooltip="Bienvenue, connectez-vous">
              Mon compte
            </span>
            <span *ngIf="isAuth && currentUser" matTooltip="Accedez à votre compte"><fa-icon
                [icon]="['fas', 'user-checked']" class="userCheckedIcon" size="lg"></fa-icon>Mon compte
            </span>
          </button>
          <mat-menu #account="matMenu">
            <ng-template matMenuContent>
              <ng-container *ngIf="!isAuth">
                <a routerLink="/account" mat-menu-item>Connexion</a>
                <a routerLink="/account/inscription" mat-menu-item>
                  Inscription
                </a>
              </ng-container>
              <ng-container *ngIf="isAuth">
                <a routerLink="/account/tableau-de-bord/accueil" mat-menu-item>Mon espace</a>
                <a routerLink="/account/tableau-de-bord/informations" mat-menu-item>Mon profil</a>
                <a routerLink="/account/tableau-de-bord/commandes" mat-menu-item>Mes commandes</a>
                <a routerLink="/account/tableau-de-bord/reprises" mat-menu-item>Mes reprises</a>
                <a routerLink="/account/tableau-de-bord/alertes" mat-menu-item>Mes alertes</a>
                <a routerLink="/account/tableau-de-bord/recherches" mat-menu-item>Mes recherches</a>
                <a routerLink="/account/tableau-de-bord/favoris" mat-menu-item>Mes favoris</a>
                <a routerLink="/account/tableau-de-bord/parametres" mat-menu-item>Paramètres</a>
                <hr class="custom-hr" />
                <button mat-menu-item (click)="signedOut()">Déconnexion</button>
              </ng-container>
            </ng-template>
          </mat-menu>
        </div>
      </div>

      <div (mouseleave)="setPopover('')" (blur)="setPopover('')" [ngClass]="{ hidden: state !== 'vehicle' }"
        class="absolute inset-x-[5%] top-full text-sm text-gray-500 mt-1">
        <div class="relative bg-white rounded-xl shadow-lg w-[1300px] mx-auto">
          <div class="mx-auto max-w-7xl px-2">
            <div class="grid grid-cols-5 gap-x-6 gap-y-8 py-8">
              <!-- MARQUES COLUMN -->
              <div>
                <p id="Marques-heading" class="titleColumn pb-3">TOP MARQUES</p>

                <div class="grid grid-cols-1 grid-flow-row gap-x-2 gap-y-2 pb-2" role="list"
                  aria-labelledby="Marques-heading">
                  <div *ngFor="let marque of marques">
                    <a class="linkNav items-center" routerLink="/achat/vehicules" [queryParams]="{ marques: [marque] }"
                      (click)="setFiltersMarque([marque])">
                      <img src="assets/images/logos-marques/{{ marque }}.png" class="w-5 mr-1.5" alt="{{ marque }}" />
                      | {{ marque }}
                    </a>
                  </div>
                </div>
                <a class="cursor-pointer text-black text-base font-normal underline underline-offset-2"
                  routerLink="/achat/vehicules" (click)="goCatalogAndResetFilters()">Tout voir</a>
              </div>

              <!-- MODLES COLUMN -->
              <div>
                <p id="range-heading" class="titleColumn pl-2 pb-3">
                  TOP MODÈLES
                </p>

                <div class="grid grid-cols-1 grid-flow-row gap-x-2 gap-y-2 pb-2" role="list"
                  aria-labelledby="range-heading">
                  <div *ngFor="let range of ranges">
                    <a class="linkNav items-start" routerLink="/achat/vehicules" [queryParams]="{
                        from: 0,
                        marques: [range.marque],
                        ranges: range.range,
                        modeles: [range.modeles]
                      }" (click)="setFiltersRanges(range)">
                      {{ range.marque }} {{ range.range }}
                    </a>
                  </div>
                </div>

                <a class="cursor-pointer text-black text-base font-normal underline underline-offset-2"
                  routerLink="/achat/vehicules" (click)="goCatalogAndResetFilters()">Tout voir</a>
              </div>

              <!-- Categories COLUMN -->
              <div>
                <p id="categories-heading" class="titleColumn pl-2 pb-3">
                  NOS CATÉGORIES
                </p>

                <div class="grid grid-cols-1 grid-flow-row gap-x-2 gap-y-2 pb-2" role="list"
                  aria-labelledby="categories-heading">
                  <div *ngFor="let category of categories">
                    <a class="linkNav items-start" routerLink="/achat/vehicules"
                      [queryParams]="{ from: 0, categories: category }" (click)="setFiltersCategories(category)">
                      {{ category }}
                    </a>
                  </div>
                </div>

                <a class="cursor-pointer text-black text-base font-normal underline underline-offset-2"
                  routerLink="/achat/vehicules" (click)="goCatalogAndResetFilters()">Tout voir</a>
              </div>

              <!-- rubriques COLUMN -->
              <div>
                <p id="rubriques-heading" class="titleColumn pl-2 pb-3">
                  NOS RUBRIQUES
                </p>

                <div class="grid grid-cols-1 grid-flow-row gap-x-2 gap-y-2 pb-2" role="list"
                  aria-labelledby="rubriques-heading">
                  <a class="linkNav items-start" routerLink="/achat/vehicules"
                    [queryParams]="{ from: 0, petits_prix: 1 }" (click)="setFilter('petits_prix', 1)">
                    Petits prix
                  </a>
                  <a class="linkNav items-start" routerLink="/achat/vehicules"
                    [queryParams]="{ from: 0, faibles_km: 1 }" (click)="setFilter('faibles_km', 1)">
                    Faibles kilométrages
                  </a>
                  <a class="linkNav items-start" routerLink="/achat/vehicules"
                    [queryParams]="{ from: 0, occasions_recentes: 1 }" (click)="setFilter('occasions_recentes', 1)">
                    Occasions récentes
                  </a>
                  <a class="linkNav items-start" routerLink="/achat/vehicules" [queryParams]="{
                      from: 0,
                      carburants: ['Hybride', 'Electrique']
                    }" (click)="setFilter('carburants', ['Hybride', 'Electrique'])">
                    Électriques & Hybrides
                  </a>
                  <a class="linkNav items-start" routerLink="/achat/vehicules"
                    [queryParams]="{ from: 0, offers: 'Promotion' }" (click)="setFilter('offers', ['Promotion'])">
                    Prix en baisse
                  </a>
                  <a class="linkNav items-start" routerLink="/achat/vehicules"
                    [queryParams]="{ from: 0, offers: 'Prime conversion' }"
                    (click)="setFilter('offers', ['Prime conversion'])">
                    Prime conversion
                  </a>
                  <a class="linkNav items-start" routerLink="/achat/vehicules" [queryParams]="{
                      from: 0,
                      offers: ['Promotion', 'Prime conversion']
                    }" (click)="
                      setFilter('offers', ['Promotion', 'Prime conversion'])
                    ">
                    Offres en cours
                  </a>
                </div>

                <a class="cursor-pointer text-black text-base font-normal underline underline-offset-2"
                  routerLink="/achat/vehicules" (click)="goCatalogAndResetFilters()">Tout voir</a>
              </div>

              <!-- ADVERTISSEMENT COLUMN -->
              <div class="group relative text-base sm:text-sm">
                <p id="Accessories-heading" class="titleColumn pb-3">
                  OFFRES DU MOMENT
                </p>
                <app-advertisement [navbar]="true"></app-advertisement>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>